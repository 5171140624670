.formField {
  width: 100%;
  margin-bottom: 24px;
  //padding-top: 6px;
  &_input_wrapper {
    position: relative;
  }
  &_input {}

  &_breakAllError {
    word-break: break-all;
  }

  &_success {
    label {
      color: var(--primary-main);
    }
    fieldset {
      border-width: 2px;
      border-color: var(--primary-main);
    }
  }
}

.mui_select_field {
  //margin-top: 6px;
}

