.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
  height: 100vh;
}

.logo {
  width: 100%;
  max-width: 214px;
  font-size: 0;
  img {
    width: 100%;
    height: auto;
  }
}
.small {
  max-width: 80px;
}

.large {
  max-width: 320px;
}

.progress {
  width: 100%;
  max-width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
