.formField {
  position: relative;
  width: 100%;
  margin-bottom: 24px;

  label {
    color: inherit!important;
  }

  &_error {
    margin: 8px 0 0;
    display: block;
    width: 100%;
    color: var(--error-main);
    font-size: 12px;
    font-weight: 400;
    line-height: 1.2;
    text-transform: none;
    text-align: left;
    transition: all .2s ease;
  }
}

.inputError {
  label {
    color: #FF3333!important;
  }
  fieldset {
    border-color: #FF3333!important;
    legend {
      color: #FF3333;
    }
  }
}

.inputErrorDef {
  fieldset {
    //border-color: rgba(0, 0, 0, 0.23)!important;
    legend {
      color: #FF3333;
    }
  }
}

.input {
  fieldset {
    //border-color: rgba(0, 0, 0, 0.23)!important;
  }
}
